import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/vultr-2-runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
import { graphql } from 'gatsby';
import { IoMdBed, GiCastle, MdBook, MdMap } from 'react-icons/all';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import FullImage from '@kastle/components/src/components/Image/FullImage';
import { CustomLayout, Head, ContentRow, ContentCol, Separator, SmallSpace } from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import Text from '@kastle/ui/src/atoms/Text/Text';
import LatestArticles from '../sections/LatestArticles';
import Presentation from '../sections/Presentation';
import { Routes } from '../routes';
export const query = graphql`
  query {
    bnb: file(
      relativePath: { eq: "2020/04/airbnb-chambres-dhotes-nancy.jpeg" }
    ) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    castle: file(relativePath: { eq: "chateau_de_morey.jpg" }) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    hall: file(
      relativePath: { eq: "location_de_salles_entre_nancy_et_metz.jpg" }
    ) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    hobby: file(
      relativePath: { eq: "place_stanislas_nancy_proche_chateau_de_morey.jpg" }
    ) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Presentation langKey={props.pageContext.langKey} mdxType="Presentation" />
    <Section type={SectionType.Secondary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
          <strong>Chambres d’hôtes</strong> et Gîte entre{' '}
          <strong>Nancy et Metz</strong>
        </Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
          Entre <strong>Nancy et Metz</strong>, à l'orée du bois, pour une
          étape d’exception. Vivez le temps d'une nuit ou d'un week end,
          dans ce château du XVIème siècle construit sur les ruines d'un
          couvent des templiers. Un lieu unique et authentique chargé
          d'histoire.
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            La famille Karst vous accueille dans l'une des 5 chambres
            d’hôtes de charme. Promenez vous au sons des oiseaux dans le
            parc arboré aux arbres centenaires.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Vue panoramique exceptionnelle sur la vallée de la Natagne.
            Piscine extérieure, mini golf, baby foot, billard, boulodrome.
            Parking clos et sécurisé. SPA et Massage sur réservation.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.fr.BNB} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><IoMdBed mdxType="IoMdBed" /></SmallSpace>
            Les chambres
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.bnb?.childImageSharp?.gatsbyImageData} mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Tertiary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">Le Château de Morey</Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
          Au coeur de la Lorraine, dans un château du 16ème siècle rénové
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Ancienne demeure des ducs de Lorraine du XVIe siècle, ce château
            a été entièrement détruit en 1985 par un incendie. Il renaît de
            ses cendres avec la famille KARST. Repos et calme assuré. Accès
            facile, 5 km de l'A31 sortie 24.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Autour de ce bed and breakfast atypique vous pourrez visiter la
            ville de Nancy qui n'est qu'à 18 km et ses nombreux monuments et
            musées retraçant l’Histoire de cette ville durant les siècles ou
            encore Metz et sont célèbre centre Pompidou.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.fr.CASTLE} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><GiCastle mdxType="GiCastle" /></SmallSpace>
            Le Château
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.castle?.childImageSharp?.gatsbyImageData} mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Secondary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
          <strong>Location de salles</strong> en <strong>Lorraine</strong>{' '}
          entre <strong>Nancy et Metz</strong>
        </Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
          Dans un cadre authentique, faites de votre{' '}
          <strong>événement</strong> un moment inoubliable. Ouvert de puis
          1998, nous louons nos <strong>salles pour des mariages</strong>,
          séminaires, anniversaires, cocktails etc... Location de 3 salles,
          cuisine pour traiteur, une partie du parc avec abri pou vin
          d'honneur.
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Si vous recherchez un <strong>lieu de mariage</strong> en{' '}
            <strong>Lorraine</strong>, à proximité de <strong>Nancy</strong>{' '}
            et <strong>Metz</strong>. Si vous voulez un cadre idéal pour
            faire de votre événement un moment inoubliable ! Venez découvrir
            le château de Morey
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            C'est un <strong>lieu magique</strong>, pour un{' '}
            <strong>mariage</strong> inoubliable ! Le château de Morey est
            un <strong>lieu exceptionnel</strong> pour organiser le plus
            beau jour de votre vie.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.fr.SPACE_RENTAL} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><MdBook mdxType="MdBook" /></SmallSpace>
            En savoir plus
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.hall?.childImageSharp?.gatsbyImageData} mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <LatestArticles mdxType="LatestArticles" />
    <Section type={SectionType.Primary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
          Loisirs au château et découverte de la <strong>Lorraine</strong> !
        </Heading>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Visiter la <strong>lorraine</strong>, voyager, c'est se laisser
            surprendre et émouvoir par l'autre et par ailleurs. Et il n'est
            pas toujours besoins d'aller au bout du monde...
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.fr.HOBBY} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><MdMap mdxType="MdMap" /></SmallSpace>
            Découvrir
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.hobby?.childImageSharp?.gatsbyImageData} mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      